<template>
  <div class="row">
    <el-empty v-if="isFalse" :description="errorMessage" class="mx-auto text-center"></el-empty>
    <div class="col-xl-12" v-else>
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                <edutalk-date-picker class="w-100 mt-3"
                                     v-model="form.selectDate"
                                     type="daterange"
                                     popper-class="edutalk-range no-feature"
                                     @focus="focus"
                                     ref="picker"
                                     @changeMonth="onchangeMonth"
                                     @change="changeFirstTable"
                                     @changeYear="onchangeMonth"
                                     :picker-options="pickerOptions"
                                     :unlink-panels="true"
                                     range-separator="-"
                                     start-placeholder="Từ ngày"
                                     end-placeholder="Đến ngày">
                </edutalk-date-picker>
              </el-col>
              <el-col v-if="isGDKV" :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                <el-select clearable v-model="form.branch_id" filterable class="w-100 mt-3"
                           placeholder="Chọn văn phòng để xem" @change="handleChangeBranch">
                  <el-option
                      v-for="item in branches"
                      :key="item.id"
                      :label="item.name_alias ? item.name_alias : item.name"
                      placeholder="Chọn cơ sở"
                      :value="item.id">
                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <!--eslint-disable-->
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading" class="btn btn-primary w-100 mt-3" @click="submit"><i
                    v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>
            </el-row>
            <el-row class="mt-5" v-loading="loadingReport">
              <div class="table-bao-cao-kinh-doanh">
                <table class="table table-hover table-bordered table-responsive">
                  <thead>
                  <tr>
                    <th class="width-title header">Ngày trong tháng</th>
                    <th class="font-size-10 text-center" style="width: 100px" >KQ tháng</th>
                    <th class="font-size-10 text-center" v-for="(value) in tableDate">{{ value }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <kd-item :table-date="tableDate" @detail="openDetail" calculate="number" :type="BAO_CAO_TYPE.CALENDAR"
                           :data="dataReport['lich_hen_du_kien']" title="Số lịch hẹn"></kd-item>

                  <kd-item :table-date="tableDate" @detail="openDetail" calculate="number" :type="BAO_CAO_TYPE.CANCEL_CALENDAR"
                           :data="dataReport['lich_hen_huy_trong_ngay']" title="Số lịch hẹn bị hủy"></kd-item>

                  <kd-item calculate="number" :table-date="tableDate" @detail="openDetail" :type="BAO_CAO_TYPE.CUSTOMER_TEST"
                           :data="dataReport['kh_len_test']" title="Số lượng KH lên test"></kd-item>

                  <kd-item :table-date="tableDate" calculate="total" @detail="openDetail"
                           :type="BAO_CAO_TYPE.BENEFIT_CUSTOMER" :data="dataReport['quyen_loi']"
                           title="Số quyền lợi" tooltip="Lần đầu đóng dưới 1200k"></kd-item>

                  <kd-item :table-date="tableDate"
                           calculate="amount" :data="dataReport['quyen_loi']" title="Quyền lợi"
                           tooltip="Doanh số lần đầu đóng dưới 1200k"></kd-item>

                  <kd-item :table-date="tableDate" @detail="openDetail" :type="BAO_CAO_TYPE.DEBT_CONTRACT"
                           calculate="total" :data="dataReport['hop_dong_no']" title="Số lượng hợp đồng nợ"
                           tooltip="Hợp đồng công nợ"></kd-item>

                  <kd-item :table-date="tableDate" calculate="amount" :data="dataReport['hop_dong_no']"
                           title="Doanh số nợ" tooltip="Doanh số hợp đồng công nợ"></kd-item>

                  <kd-item :table-date="tableDate" @detail="openDetail" :type="BAO_CAO_TYPE.NEW_CONTRACT"
                           calculate="total" :data="dataReport['hop_dong_moi']" title="Số lượng hợp đồng mới"
                           tooltip="Lần đầu đóng từ 1200k trở lên"></kd-item>
                  <kd-item :table-date="tableDate" @detail="openDetail" :type="BAO_CAO_TYPE.NEW_REVENUE"
                           calculate="amount" :data="dataReport['hop_dong_moi']" title="Doanh số Khách hàng mới"
                           tooltip="Doanh số khách hàng mới đóng lần thứ 2 trở đi trong tháng"></kd-item>

                  <kd-item :table-date="tableDate" :data="{
                    'hop_dong_moi': dataReport['hop_dong_moi'],
                    'kh_len_test': dataReport['kh_len_test'],
                    'hop_dong_noi_bo': dataReport['hop_dong_noi_bo']
                  }" special="percent" title="Tỷ lệ chốt"
                           tooltip="(Số HĐ mới + Số HĐ nội bộ) / Tổng Số KH lên test"></kd-item>

                  <kd-item :table-date="tableDate" @detail="openDetail" :type="BAO_CAO_TYPE.INTERNAL_CONTRACT"
                           calculate="total" :data="dataReport['hop_dong_noi_bo']" title="Số lượng hợp đồng nội bộ"
                           tooltip="Số lượng HĐ có [Mã giới thiệu] = [Mã nhân sự]"></kd-item>

                  <kd-item :table-date="tableDate" calculate="amount" :data="dataReport['hop_dong_noi_bo']"
                           title="Doanh số nội bộ"
                           tooltip="Doanh số hợp đồng có [Mã giới thiệu] = [Mã nhân sự]"></kd-item>

                  <kd-item :table-date="tableDate" :data="{
                    'quyen_loi': dataReport['quyen_loi'],
                    'hop_dong_moi': dataReport['hop_dong_moi'],
                    'hop_dong_no': dataReport['hop_dong_no'],
                    'hop_dong_noi_bo': dataReport['hop_dong_noi_bo'],
                    'rut_tien': dataReport['rut_tien'],
                  }" special="total" title="Tổng doanh số"
                           tooltip="Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền "></kd-item>

                  <kd-item :table-date="tableDate" @detail="openDetail" :type="BAO_CAO_TYPE.WITHDRAW_MONEY" calculate="withdraw"
                           :data="dataReport['rut_tien']" title="Số tiền rút"></kd-item>
                  <kd-item :table-date="tableDate" @detail="openDetail" :type="BAO_CAO_TYPE.REFUND" calculate="refund"
                           :data="dataReport['rut_tien']" title="Số tiền hoàn"></kd-item>
                  </tbody>
                </table>
              </div>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialog.active">
      <el-row v-loading="dialog.loading" :gutter="10" class="pr-2 pl-2">
        <vue-good-table
            :columns="columns"
            :line-numbers="true"
            :pagination-options="{
                enabled: rows.length > 10,
                perPage: 10,
                setCurrentPage: 1,
            }"
            :pageChanged="onPageChange"
            styleClass="vgt-table table m-0 table-bordered table-hover table-striped"
            :rows="rows">
          <template slot="pagination-bottom" slot-scope="props">
            <edutalk-paginate
                :total="props.total"
                :pageChanged="props.pageChanged"
                :perPageChanged="props.perPageChanged"
                :paging="props">
            </edutalk-paginate>
          </template>
        </vue-good-table>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {REPORT_KINH_DOANH_VAN_PHONG, REPORT_KINH_DOANH_DOI_CHI_TIET} from "@/core/services/store/report.module";
import EdutalkPaginate from "../edutalk/edutalk-paginate";
import {BAO_CAO_CHI_NHANH, BAO_CAO_TYPE} from '@/core/config/baocao-config';
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import KdItem from "@/views/pages/report/kinh-doanh-doi-item";
import {mapGetters} from "vuex";
import {BRANCH_LIST} from "../../../core/services/store/service.module";

const _ = deepdash(lodash);
export default {
  name: "kinh-doanh-van-phong",
  components: {
    EdutalkPaginate,
    KdItem
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: this.disabledDate,
        onPick: this.onPick
      },
      currentDate : new Date(),
      publicPath: process.env.VUE_APP_BASE_URL,
      minDate: null,
      maxDate: null,
      startOfMonth: null,
      endOfMonth: null,
      loading: false,
      loadingReport: false,
      yearSelection: null,
      isGDKV: false,
      isGDVP : false,
      isFalse : false,
      errorMessage : '',
      BAO_CAO_TYPE: BAO_CAO_TYPE,
      options: [],
      form: {
        branch_id: '',
        selectDate: '',
        month: null
      },
      branches: [],
      dataReport: [],
      tableDate: [],
      dialog: {
        active: false,
        title: null,
        loading: false
      },
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: ""
          }
        },
        {
          label: 'Age',
          field: 'age',
          type: 'number',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: ""
          }
        },
        {
          label: 'Created On',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'MMM do yy',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: ""
          }
        },
        {
          label: 'Percent',
          field: 'score',
          type: 'percentage',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: ""
          }
        },
      ],
      rows: [],
      onPageChange: this.handleOnPageChange,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách"},
      {title: "Kinh doanh văn phòng", icon: 'far fa-money-bill'}
    ]);
    if (this.currentUser.profile.khuvuc_id > 0 && this.currentUser.profile.account_type.name === 'GĐKV') {
      this.isGDKV = true;
      this.getCenter();
      this.querySever();
      this.handleChangeBranch();
    } else {
      if (_.indexOf(['TPNS', 'TPOnline', 'TPTT', 'GĐVP'], this.currentUser.profile.account_type.name) > -1) {
        this.form.branch_id = this.currentUser.profile.chi_nhanh_id;
      }
      this.currentDate = new Date();
    }
    this.mountedDate();
    this.changeTable(false);
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    customPageChange(customCurrentPage) {
      this.pageChanged({currentPage: customCurrentPage});
    },

    customPerPageChange(customPerPage) {
      this.perPageChanged({currentPerPage: customPerPage});
    },

    disabledDate(date) {
      let endOfCurrentMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
      let endMonth = this.endOfMonth > endOfCurrentMonth ? endOfCurrentMonth : this.endOfMonth;
      if (this.minDate) {
        return this.startOfMonth > date || date > endMonth;
      }
      return date > endOfCurrentMonth;
    },

    onPick({maxDate, minDate}) {
      this.minDate = minDate;
      this.maxDate = maxDate;
      if (this.minDate) {
        let date = new Date(this.minDate);
        let year = date.getFullYear(), month = date.getMonth();
        this.startOfMonth = new Date(year, month, 1);
        this.endOfMonth = new Date(year, month + 1, 0);
      }
    },

    onchangeMonth(item) {
      let date = new Date(item.date);
      let year = date.getFullYear(), month = date.getMonth();
      let startOfMonth = new Date(year, month, 1);
      let endOfMonth = new Date(year, month + 1, 0);
      this.form.selectDate = [startOfMonth, endOfMonth];
    },

    changeFirstTable(item) {
      this.form.selectDate = item;
      this.handleChangeBranch();
    },

    focus() {
      this.minDate = null;
      this.mountedDate();
    },

    mountedDate() {
      if (!this.form.selectDate) {
        let date = new Date();
        let year = date.getFullYear(), month = date.getMonth();
        let startOfMonth = new Date(year, month, 1);
        let endOfMonth = new Date(year, month + 1, 0);
        this.form.selectDate = [startOfMonth, endOfMonth];
      }
    },

    changeTable(is_submit) {
      let tableDate = [];
      let maxDate = this.$moment(this.form.selectDate[1]);
      this.yearSelection = this.$moment(this.form.selectDate[1]).format('YYYY');
      for (let i = 0; i < 31; i++) {
        let date = this.$moment(this.form.selectDate[0]).add(i, 'days');
        if (date <= maxDate) {
          tableDate.push(date.format('DD/MM'))
        }
      }
      this.tableDate = tableDate;
      if (!is_submit) this.querySever();
    },

    submit() {
      this.changeTable(true);
      this.querySever();
    },

    querySever(customProperties) {
      this.loading = true;
      this.loadingReport = true;
      let params = this.mergeParams(customProperties);
      this.currentPage = 1;
      this.$store.dispatch(REPORT_KINH_DOANH_VAN_PHONG, params)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.dataReport = response;
            }
            this.loading = false;
            this.loadingReport = false;
          })
          .catch((res) => {
            this.isFalse = true;
            console.log(res)
          })
    },

    mergeParams(customProperties) {
      let params = {};
      if (this.form.selectDate) {
        let minDate = this.$moment(this.form.selectDate[0]).format('YYYY-MM-DD');
        let maxDate = this.$moment(this.form.selectDate[1]).format('YYYY-MM-DD');
        params = _.merge(params, {startDate: minDate, endDate: maxDate});
      }
      if (this.form.branch_id) {
        params = _.merge(params, {branch_id: this.form.branch_id})
      }
      params = _.merge(params, customProperties);
      return params;
    },

    openDetail(item) {
      if (item.number !== 0) {
        this.dialog.active = true
        this.dialog.loading = true
        let config = BAO_CAO_CHI_NHANH[item.type]
        this.columns = config.columns
        this.dialog.title = config.title;
        let params = {
          type: item.type,
          date: `${item.day}/${this.yearSelection}`,
          branch_id : this.form.branch_id,
          report_type : 'Kinh doanh van phong',
          idCreated: this.dataReport.created
        }
        this.rows = []
        this.$store.dispatch(REPORT_KINH_DOANH_DOI_CHI_TIET, params)
            .then((response) => {
              if (response.status === 422) {
                this.$message({type: 'warning', message: `${response.message}`, showClose: true});
              } else {
                this.rows = response.data;
              }
              this.dialog.loading = false
            })
      }
    },
    handleChangeBranch() {
      // eslint-disable-next-line no-unused-vars
      let params = {};
      if (this.form.branch_id > 0) {
        params = { branch_id: this.form.branch_id }
        if (this.form.selectDate) {
          params = _.merge(params, { viewDate: this.$moment(this.form.selectDate[0]).format('YYYY-MM') })
        }
      } else {
        params = { branch_id: this.currentUser.profile.chi_nhanh_id }
        if (this.form.selectDate) {
          params = _.merge(params, { viewDate: this.$moment(this.form.selectDate[0]).format('YYYY-MM') })
        }
      }
    },
    handleOnPageChange(event) {
      this.currentPage = event
    },
    getCenter() {
      this.$store.dispatch(BRANCH_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.branches = response.data;
              this.form.branch_id = response.data.length > 0 ? response.data[0].id : 0;
              this.querySever({branch_id: this.form.branch_id});
              this.handleChangeBranch()
            }
          })
    },
  },
}
</script>
