<template>
  <tr>
    <td class="width-title" :class="{ 'unset-border': className }">{{ title }}
      <el-tooltip class="item" effect="dark" v-if="tooltip" :content="tooltip" placement="right-start">
        <i class="text-primary fa fa-fw fa-info-circle"></i>
      </el-tooltip>
    </td>
    <td class="text-center total" style="width: 300px">
        <b v-if="special !== 'percent'">{{ total | formatVNDNoPrefix }}</b>
        <b v-else >{{ total }}</b>
    </td>
    <td class="text-center" v-for="(day, key) in tableDate" :key="key">
      <a v-if="type && !special" class="text-info" href="javascript:void(0);" @click="openDetail(day, renderValue(day))"><b>
        {{ renderValue(day) | formatVNDNoPrefix }} </b></a>
      <a v-else-if="special === 'percent'"> {{ convertPercent(day) }} </a>
      <a v-else-if="special === 'total'"> {{ convertTotal(day) | formatVNDNoPrefix }} </a>
      <a v-else> {{ renderValue(day) | formatVNDNoPrefix}}</a>
    </td>
  </tr>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);


export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    calculate: {
      type: String,
      default: ''
    },
    special: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    data: [Object, Array],
    tableDate: Array,
  },
  data() {
    return {
      total: 0,
      percent: null
    }
  },
  watch: {
    data: function () {
      this.sumData()
    }
  },
  mounted() {
    this.sumData();
  },
  methods: {
    renderValue(day) {
      if (this.data && this.data[day]) {
        return this.calculate ? this.data[day][this.calculate] : this.data[day]
      }
      return 0
    },
    convertPercent(day) {
      let so_hop_dong_moi = this.data && this.data['hop_dong_moi'] && this.data['hop_dong_moi'][day] ? this.data['hop_dong_moi'][day].total : 0;
      let so_hop_dong_noi_bo = this.data && this.data['hop_dong_noi_bo'] && this.data['hop_dong_noi_bo'][day] ? this.data['hop_dong_noi_bo'][day].total : 0;
      let so_kh_len_test = this.data && this.data['kh_len_test'] && this.data['kh_len_test'][day] ? this.data['kh_len_test'][day].number : 0;
      if (so_kh_len_test > 0) {
        let percent = Math.round((so_hop_dong_moi + so_hop_dong_noi_bo) / so_kh_len_test * 100);
        return `${percent} %`
      }
      return `0 %`
    },
    convertTotal(day) {
      let so_quyen_loi = this.data && this.data['quyen_loi'] && this.data['quyen_loi'][day] ? this.data['quyen_loi'][day].amount : 0;
      let so_hop_dong_moi = this.data && this.data['hop_dong_moi'] && this.data['hop_dong_moi'][day] ? this.data['hop_dong_moi'][day].amount : 0;
      let so_hop_dong_no = this.data && this.data['hop_dong_no'] && this.data['hop_dong_no'][day] ? this.data['hop_dong_no'][day].amount : 0;
      let so_hop_dong_noi_bo = this.data && this.data['hop_dong_noi_bo'] && this.data['hop_dong_noi_bo'][day] ? this.data['hop_dong_noi_bo'][day].amount : 0;
      let so_rut_tien = this.data && this.data['rut_tien'] && this.data['rut_tien'][day] && this.data['rut_tien'][day].withdraw ? this.data['rut_tien'][day].withdraw : 0;
      let so_tien_hoan = this.data && this.data['rut_tien'] && this.data['rut_tien'][day] && this.data['rut_tien'][day].refund ? this.data['rut_tien'][day].refund : 0;
      return (so_quyen_loi + so_hop_dong_moi + so_hop_dong_no + so_hop_dong_noi_bo + so_rut_tien + so_tien_hoan);
    },
    sumData() {
      let total = 0;
      _.each(this.data, (item, key) => {
        if (key !== 'none') {
          if (this.calculate) {
            let number = (this.calculate && item[this.calculate] ? item[this.calculate] : 0);
            total += number
          } else {
            total += item
          }
        }
      });
      this.total = total;
      if (this.special === 'total') {
        this.total = 0;
        let total_benefit = 0;
        let total_new_contract = 0
        let total_debt_contract = 0
        let total_internal_contract = 0
        let total_withdraw_money = 0;
        let total_refund_money = 0;
        _.each(this.data['quyen_loi'], (item, key) => {
          if (key !== 'none') {
            total_benefit += (item['amount'] ? item['amount'] : 0);
          }
        });
        _.each(this.data['hop_dong_moi'], (item, key) => {
          if (key !== 'none') {
            total_new_contract += (item['amount'] ? item['amount'] : 0);
          }
        });
        _.each(this.data['hop_dong_no'], (item, key) => {
          if (key !== 'none') {
            total_debt_contract += (item['amount'] ? item['amount'] : 0);
          }
        });
        _.each(this.data['hop_dong_noi_bo'], (item, key) => {
          if (key !== 'none') {
            total_internal_contract += (item['amount'] ? item['amount'] : 0);
          }
        });
        _.each(this.data['rut_tien'], (item, key) => {
          if (key !== 'none') {
            total_withdraw_money += (item['withdraw'] ? item['withdraw'] : 0);
          }
        });

        _.each(this.data['rut_tien'], (item, key) => {
          if (key !== 'none') {
            total_refund_money += (item['refund'] ? item['refund'] : 0);
          }
        });
        let total = total_benefit + total_new_contract + total_debt_contract + total_internal_contract + total_withdraw_money + total_refund_money
        this.total = `${total} `
      }
      if (this.special === 'percent') {
        this.total = 0;
        let total_new_contract = 0;
        let total_kh_test = 0
        let so_hd_noi_bo = 0
        _.each(this.data['hop_dong_moi'], (item, key) => {
          if (key !== 'none') {
            total_new_contract += (item['total'] ? item['total'] : 0);
          }
        });
        _.each(this.data['hop_dong_noi_bo'], (item, key) => {
          if (key !== 'none') {
              so_hd_noi_bo += (item['total'] ? item['total'] : 0);
          }
        });
        _.each(this.data['kh_len_test'], (item, key) => {
          if (key !== 'none') {
            total_kh_test += item['number']
          }
        });
        let percent = (total_new_contract > 0 || so_hd_noi_bo > 0 ) && total_kh_test > 0 ? Math.round((total_new_contract + so_hd_noi_bo) / total_kh_test * 100) : 0;
        this.total = `${percent} %`
      }
    },
    openDetail(day, number) {
      this.$emit('detail', {
        type: this.type,
        day: day,
        number: number
      })
    }
  },
}
</script>

<style scoped>

</style>
